import '../assets/images/logo.png';
import '../assets/images/certified.png';
import '../assets/images/ansys-cecp_white.png';
import '../assets/images/ansys-cecp_black.png';
import '../assets/images/circle-checked.png';
import '../assets/images/check.png';
import '../assets/images/chevron-right.png';
import '../assets/images/contacts-map.png';
import '../assets/images/OG-image.png';

import '../assets/images/post-ph-bg-img.jpg';
import '../assets/images/post-banner-ph.jpg';

import '../assets/images/noimage.jpg';
import '../assets/images/noimage-square.jpg';

import '../assets/images/plus.png';
import '../assets/images/plus-primary.png';
import '../assets/images/minus-primary.png';
import '../assets/images/chevron-right-dark.png';

import '../assets/images/browsers/chrome.png';
import '../assets/images/browsers/firefox.png';
import '../assets/images/browsers/opera.png';
import '../assets/images/browsers/edge.png';
