import { LazyObserver } from "cadfem-modules/src/classes";
import { log } from "cadfem-modules/utility";
import moduleMap from './moduleMap';

[
  "MapTabTrigger",
  "PhoneField",
  "Select2",
  "HeaderSticky",
  "GMapTabs",
  "NavBar",
  "Form",
  "FormCaptcha",
  "DropDown",
  "TextCut",
  "ScrollTop",
  "SmoothScroll",
  "Share",
  "CookiePopup",
  "PromoPopup",
].forEach( moduleName => {
  const block = moduleMap.get(moduleName);
  (new LazyObserver({
    selector: block.selector || `[data-component="${moduleName}"]`,
    activeClass: 'lazy',
    observe: ({target, IO, activeClass}) => {
      const version = block.v ? `_v${block.v}` : '';
      block._()
        .then( module => (new module.default(moduleName, target, block.params)).start() )
        .then( () => log(`${moduleName}${version}`) )
        .then( () => target.removeAttribute(activeClass) )
        .catch( error => console.error(`An error occurred while loading the component ${moduleName}`, error) );
      IO.unobserve(target);
    }
  })).init();
});

/*import('./local/Xmas')
  .then( module => (new module.default()).start() )
  .catch( error => console.error(`An error occurred while loading the component Xmas`, error) );*/
