import { LazyObserver } from "cadfem-modules/src/classes";
import opts from '../settings/mainSliderOpts';

const selector = '[jumbotronMainSlider]';
const sideSelector = '[jumbotronSideSlider]';
const sideSliderOpts = {
  direction: 'vertical',
  slidesPerView: 5,
  slidesPerGroup: 1,
  followFinger: false,
  longSwipes: false,
  shortSwipes: false,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
};

(new LazyObserver({
    selector: selector,
    activeClass:'data-jumbotronMainSlider-lazy',
    observe: ({target, IO, activeClass}) => {
      import('swiper/js/swiper.min')
        .then( module =>{
          new module.default(selector, {
            ...opts(selector),
            /*...{ observer:true, observeParents:true, thumbs:{ swiper:(new module.default(sideSelector, sideSliderOpts)) }, }*/
          })
        })
        .then( () => target.removeAttribute(activeClass) )
        .catch(error => console.error(error));

      IO.unobserve(target);
    }
  })
).init();
