export default new Map([

  ["Select2",       { v:3, _:()=>import('cadfem-modules/src/sync/Select2_v3'), selector:'.select-2' } ],
  ["HeaderSticky",  { v:2, _:()=>import('cadfem-modules/src/sync/HeaderSticky_v2'), }],
  ["GMapTabs",      { v:3, _:()=>import('cadfem-modules/src/sync/GMapTabs_v3'), params:{ key:ENV.GMapKey || null }, }],
  ["NavBar",        { v:2, _:()=>import('cadfem-modules/src/sync/NavBar_v2'), }],
  ["Form",          { v:2, _:()=>import('cadfem-modules/src/sync/Form_v2'), }],
  ["FormCaptcha",   { v:0, _:()=>import('cadfem-modules/src/sync/FormCaptcha'), }],
  ["DropDown",      { v:2, _:()=>import('cadfem-modules/src/sync/DropDown_v2'), }],
  ["TextCut",       { v:2, _:()=>import('cadfem-modules/src/sync/TextCut_v2'), }],
  ["ScrollTop",     { v:2, _:()=>import('cadfem-modules/src/sync/ScrollTop_v2'), }],
  ["SmoothScroll",  { v:2, _:()=>import('cadfem-modules/src/sync/SmoothScroll_v2'), }],

  ["Share",         { v:0, _:()=>import('./local/ShareNew'), }],
  ["CookiePopup",   { v:0, _:()=>import('./local/CookiePopup'), }],
  ["PromoPopup",    { v:0, _:()=>import('./local/PromoPopup'), }],
  ["MapTabTrigger", { v:0, _:()=>import('./local/MapTabTrigger') } ],
  ["PhoneField",    { v:0, _:()=>import('./local/PhoneField'), selector:'.inputmask-phone' } ],
]);
