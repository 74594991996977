import { LazyObserver } from "cadfem-modules/src/classes";


const selector = '[pswpGallery]';

(new LazyObserver({
    selector: selector,
    activeClass:'data-pswpGallery-lazy',
    observe: ({target, IO, activeClass}) => {
      let PhotoSwipe = null;
      let PhotoSwipeUI = null;
      import(/* webpackChunkName: "PhotoSwipe[js]" */ 'photoswipe/dist/photoswipe')
        .then((result) => {
          PhotoSwipe = result.default;
          return import(/* webpackChunkName: "PhotoSwipe[js][skin]" */ 'photoswipe/dist/photoswipe-ui-default').then((response) => response);
        })
        .then((result) => {
          PhotoSwipeUI = result.default;
          return import('./../initPhotoSwipeFromDOM').then((response) => response);
        })
        .then((result) => {
          result.default('[pswpGallery]', PhotoSwipe, PhotoSwipeUI);
        })
        .then( () => target.removeAttribute(activeClass) )
        .catch(error => console.error(error));

      IO.unobserve(target);
    }
  })
).init();
