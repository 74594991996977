// Decorators test
/*
const isRequired = function(prop, propName){
  if (!prop){
    throw new Error(`prop ${propName} is required!`);
  }
};

@classLog
class MyClass {
  constructor(name, debug) {

    isRequired.call(this, name, 'name');

    this.name = name;
    this.debug = debug;
  }
}
function classLog(Class) {
  const name = Class.prototype.constructor.name;
  console.log(`Class ${name} has been just started!`);
  return (...args) => {
    console.log('with params: ');
    console.log(args);
    return (new Class(...args));
  }
}
const customClass = new MyClass('blabla', true);
console.log(customClass);
const customClass2 = new MyClass();
console.log(customClass2);
*/

// lazy
import './lazy/mainSlider';
import './lazy/img';
import './lazy/bg';
import './lazy/styles';
import './lazy/pswpGallery';

// import modules
import './modules/cadfem';
import './modules/bootstrap';

// images
import './assets';
import classPrivateFieldGet
  from '@babel/runtime/helpers/esm/classPrivateFieldGet';

// require all svg in svg folder
import('./additional/svgRequire');

function autoPlayYouTubeModal() { // TODO: make through bootstrap modal native methods
  const trigger = $('body').find('[data-yt]');
  trigger.click(function triggerCb() {
    const theModal = $(this).data('target');
    const videoSRC = $(this).attr('data-video-url');
    const videoSRCauto = `${videoSRC}?autoplay=1&rel=0`;
    $(`${theModal} iframe`).attr('src', videoSRCauto);
    $(theModal).on('hidden.bs.modal', () => {
      $(`${theModal} iframe`).attr('src', videoSRC);
    });
  });
}
autoPlayYouTubeModal();

/*const isRequired = function(prop, propName){
  if (!prop){
    throw new Error(`prop ${propName} is required!`);
  }
};
function getDamageMixin(Class) {
  Class.prototype.getDamage = function(value){
    this.health = this.health - value;
    return this.health;
  };
  return (...args) => (new Class(...args));
}



const makeDamage = (value, Victim) => {
  Victim.getDamage(value);
};

class Character {
  constructor({name}){
    this.name = name;
  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

class FightCharacter extends Character {
  constructor({name}){
    super({name});
    this._maxHealth = null;
    this._health = null;
  }
  get health(){
    return this._health;
  }
  set health(value){
    this._health = value;
    return this._health;
  }
  attack(){
    const attackValue = this.health * 0.1 * getRandomInt(4);
    console.log(attackValue);
    return attackValue;
  }
}

@getDamageMixin
class Warrior extends FightCharacter {
  constructor({name}){
    super({name});
    this._maxHealth = 200;
    this._health = 200;
  }
}

@getDamageMixin
class Wizard extends FightCharacter {
  constructor({name}){
    super({name});
    this._maxHealth = 100;
    this._health = 100;
  }
}


const SimpleCharacter = new Character({name:"SimpleCharacter"});
const WarriorCharacter = new Warrior({name:"WarriorCharacter"});
const WizardCharacter = new Wizard({name:"WizardCharacter"});

console.log(SimpleCharacter);

console.log(WizardCharacter);
console.log(WarriorCharacter);
WarriorCharacter.__proto__.bla = 'sdfsdfsdf';
console.log(WarriorCharacter);
console.log('WarriorCharacter health level: ' + WarriorCharacter.health);
console.log('WizardCharacter health level: ' + WizardCharacter.health);
console.log('WarriorCharacter attack WizardCharacter');
makeDamage(WarriorCharacter.attack(), WizardCharacter);
console.log('WarriorCharacter health level: ' + WarriorCharacter.health);
console.log('WizardCharacter health level: ' + WizardCharacter.health);
console.log('WizardCharacter attack WarriorCharacter');
makeDamage(WizardCharacter.attack(), WarriorCharacter);
console.log('WarriorCharacter health level: ' + WarriorCharacter.health);
console.log('WizardCharacter health level: ' + WizardCharacter.health);*/
/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
/*function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}*/
/**
 * @param {String} HTML representing any number of sibling elements
 * @return {NodeList}
 */
/*function htmlToElements(html) {
  var template = document.createElement('template');
  template.innerHTML = html;
  return template.content.childNodes;
}*/
/*const Comment = {
  id: "",
  author:"",
  text:"",
};
class CommentList {
  constructor({comments=[]}) {
    this._comments = comments || [];
  }
  get comments(){
    return this._comments;
  }
  addComment(item){
    this._comments = [...this._comments, item];
    return item;
  }
  deleteComment(id){
    this._comments = this._comments.filter( comment => comment.id !== id );
  }
}

class CommentListMarkup {
  constructor({comments=[]}) {
    this.comments = comments || [];
    this.list = document.createElement('ul');
  }
  renderComment(comment){
    return `<li id="comment-${comment.id}"><b>${comment.author}:</b> - ${comment.text} <a href="#remove">remove</a></li>`;
  }
  renderList(){
    const rendered = `${this.comments.reduce( (acc,comment) => (acc+=this.renderComment(comment)), '' )}`;
    const elements = htmlToElements(rendered);
    this.list.append(...elements);

    /!*this.comments.forEach( comment => this.list.append( htmlToElement( this.renderComment(comment) ) ) );*!/
    return this.list;
  }
  insertComment(comment){
    this.list.append(htmlToElement(this.renderComment(comment)));
    return this.list;
  }
  deleteComment(id){
    document.getElementById('comment-'+id).remove();
  }
}
const arr = [...Array(1000).keys()];
// console.log(arr);

console.time();
const comments = arr.reduce((acc,item)=>[...acc, {id:item,author:'me',text:'#'+item + ' comment'}],[]);*/

/*const comments = [
  {
    id: 1,
    author: "me",
    text:"first comment",
  },
  {
    id: 2,
    author: "me",
    text:"second comment",
  }
];*/
/*const DocumentComments = new CommentListMarkup({comments:comments});
const commentsPlace = document.querySelector('.js-comments');
const listEl = DocumentComments.renderList();
// console.log(listEl);
commentsPlace.append(listEl);
console.timeEnd();


setTimeout(()=>{
  DocumentComments.insertComment({
    id: 999999,
    author: "someone",
    text:"added comment",
  });
},3000);
setTimeout(()=>{
  DocumentComments.deleteComment(1);
},5000);

var td = htmlToElement('<td>foo</td>'),
  div = htmlToElement('<div><span>nested</span> <span>stuff</span></div>');

var rows = htmlToElements('<div><tr><td>foo</td></tr><tr><td>bar</td></tr></div>');
console.log(div);*/
// commentsPlace.append(rows);
/*
console.log(DocumentComments);
console.log(DocumentComments.comments);
DocumentComments.addComment({
  id: 1,
  author: "me",
  text:"first comment",
});
DocumentComments.addComment({
  id: 2,
  author: "me",
  text:"second comment",
});
console.log(DocumentComments.comments);
DocumentComments.deleteComment(1);
console.log(DocumentComments.comments);
*/



