export default (selector) => ({
  loop: true,
  simulateTouch: false,
  pagination: {
    el: `${selector} .jumbotronSlider__pagination`,
    type: 'fraction',
  },
  navigation: {
    nextEl: `${selector} .jumbotronSlider__next`,
    prevEl: `${selector} .jumbotronSlider__prev`,
  },
  autoplay: {
    disableOnInteraction:false,
    delay: 5000,
  },
});
