import { LazyObserver } from "cadfem-modules/src/classes";


(new LazyObserver({
  selector: `[data-styles-lazy]`,
  activeClass: 'js-hidden',
  observe: ({target, IO, activeClass}) => {
    const blockName = target.getAttribute('data-styles-lazy');
    import(/* webpackChunkName: "STYLES/[request]" */ `./../../scss/COMPONENTS/desktop/${blockName}.scss`)
      .then( () => target.classList.remove(activeClass) )
      .then( () => target.removeAttribute('data-styles-lazy') );

    IO.unobserve(target);
  }
})).init();

